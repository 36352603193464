import { action, makeObservable, observable, runInAction, toJS } from 'mobx'

import { serviceAdapter } from '../service/serviceAdapter'
import { authStore, searchStore } from '../store'
import { addValuesToLabels, arrayIsEmpty } from '../utils/helpers'
import { OutboundMessageType, sendMessage } from '../utils/postMessage'
import { DropDownType, IDropDownOption, IInvite, IUserDetailOrganisation } from './dataModels/interfaces'

export class UserDetailsStore {
  userId: string = ''

  step: number = 1

  firstName: string

  lastName: string

  email: string

  phone: string

  invites: IInvite[]

  isLoading: boolean = true

  userOrganizations: IUserDetailOrganisation[]

  changesMade: boolean = false

  showChangesMadeNotification: boolean = true

  pendingRequest: string = ''

  //  Numbers to be displayed in step 2
  customerNumbers: IDropDownOption[] | null = null

  LSCNumbers: IDropDownOption[] | null = null

  transportIds: IDropDownOption[] | null = null

  businessPartnerNumbers: IDropDownOption[] | null = null

  principalIds: IDropDownOption[] | null = null

  setStep = (step: number) => {
    this.step = step
  }

  constructor() {
    makeObservable(this, {
      userId: observable,
      step: observable,
      firstName: observable,
      lastName: observable,
      email: observable,
      phone: observable,
      invites: observable,
      isLoading: observable,
      userOrganizations: observable,
      changesMade: observable,
      showChangesMadeNotification: observable,
      pendingRequest: observable,
      customerNumbers: observable,
      LSCNumbers: observable,
      transportIds: observable,
      businessPartnerNumbers: observable,
      principalIds: observable,
      setStep: action,
      fetchUserData: action,
      toggleUserRole: action,
      setChangesMade: action,
      setShowChangesMadeNotification: action,
      getCustomerNumbersAndServiceContracts: action,
      setSelectedCustomerNumbers: action,
      setSelectedLSCNumbers: action,
      setSelectedTransportIds: action,
      setSelectedBusinessPartnerNumbers: action,
      setSelectedPrincipalIds: action,
      postChangesToBackend: action,
      setPendingRequest: action,
      removeOrganisation: action,
    })
  }

  async fetchUserData(organizationUserId: string): Promise<void> {
    try {
      runInAction(() => {
        this.isLoading = true
      })

      const response = await serviceAdapter.sendGetRequest(`/api/userdetails/${organizationUserId}`)

      if (response.status >= 400) {
        throw new Error('Bad response from server')
      }
      const data = await response.json()

      runInAction(() => {
        this.userId = organizationUserId
        this.firstName = data.firstName
        this.lastName = data.lastName
        this.email = data.email
        this.phone = data.phone
        this.userOrganizations = data.organizations
        this.invites = data.invites

        this.isLoading = false
      })
    } catch (err) {
      console.error(err)
    }
  }

  toggleUserRole = (orgId: string, userRole: string) => {
    this.setChangesMade(true)

    const organisationData = this.userOrganizations.find((org) => org.organization.businessId === orgId)

    const { organizationUser } = organisationData
    const { selectedRoles } = organizationUser

    if (!selectedRoles.includes(userRole)) {
      return selectedRoles.push(userRole)
    }

    const newRoles = selectedRoles.filter((role) => role !== userRole)
    const idx = this.userOrganizations.findIndex((org) => org.organization.businessId === orgId)
    this.userOrganizations[idx].organizationUser.selectedRoles = newRoles

    if (arrayIsEmpty(newRoles)) {
      this.setChangesMade(false)
    }
  }

  setChangesMade = (made: boolean) => {
    this.changesMade = made
  }

  setShowChangesMadeNotification = (show: boolean) => {
    this.showChangesMadeNotification = show
  }

  getCustomerNumbersAndServiceContracts = async (orgId: string, selectedRoles: string[]) => {
    try {
      runInAction(() => {
        this.pendingRequest = 'loading'
      })

      const postObject = {
        roles: toJS(selectedRoles),
      }

      const response = await serviceAdapter.sendPostRequest(`/api/contract-numbers/${orgId}/available`, postObject)

      if (response.status >= 400) {
        runInAction(() => {
          this.pendingRequest = 'error'
        })

        throw new Error('Bad response from server')
      }

      const data = await response.json()

      runInAction(() => {
        this.customerNumbers = arrayIsEmpty(data.customerNumbers)
          ? []
          : addValuesToLabels(data.customerNumbers, DropDownType.CUSTOMER_NUMBER)
        this.LSCNumbers = arrayIsEmpty(data.logisticsContractNumbers)
          ? []
          : addValuesToLabels(data.logisticsContractNumbers, DropDownType.LOGISTICS_CONTRACT_NUMBER)
        this.transportIds = arrayIsEmpty(data.transportIds)
          ? []
          : addValuesToLabels(data.transportIds, DropDownType.TRANSPORT_ID)
        this.businessPartnerNumbers = arrayIsEmpty(data.businessPartnerNumbers)
          ? []
          : addValuesToLabels(data.businessPartnerNumbers, DropDownType.BUSINESS_PARTNER_NUMBER)
        this.principalIds = arrayIsEmpty(data.principalIds)
          ? []
          : addValuesToLabels(data.principalIds, DropDownType.PRINCIPAL_ID)
        this.pendingRequest = ''
      })
    } catch (err) {
      console.error(err)
      runInAction(() => {
        this.pendingRequest = 'error'
      })
    }
  }

  setSelectedCustomerNumbers = (orgId: string, selectedCustomerNumbers: string[]) => {
    const idx = this.userOrganizations.findIndex((org) => org.organization.businessId === orgId)

    this.userOrganizations[idx].organizationUser.selectedCustomerNumbers = selectedCustomerNumbers
    this.setChangesMade(true)
  }

  setSelectedLSCNumbers = (orgId: string, selectedLogisticsContractNumbers: string[]) => {
    const idx = this.userOrganizations.findIndex((org) => org.organization.businessId === orgId)

    this.userOrganizations[idx].organizationUser.selectedLogisticsContractNumbers = selectedLogisticsContractNumbers
    this.setChangesMade(true)
  }

  setSelectedTransportIds = (orgId: string, selectedTransportIds: string[]) => {
    const idx = this.userOrganizations.findIndex((org) => org.organization.businessId === orgId)

    this.userOrganizations[idx].organizationUser.selectedTransportIds = selectedTransportIds
    this.setChangesMade(true)
  }

  setSelectedBusinessPartnerNumbers = (orgId: string, selectedBusinessPartnerNumbers: string[]) => {
    const idx = this.userOrganizations.findIndex((org) => org.organization.businessId === orgId)

    this.userOrganizations[idx].organizationUser.selectedBusinessPartnerNumbers = selectedBusinessPartnerNumbers
    this.setChangesMade(true)
  }

  setSelectedPrincipalIds = (orgId: string, selectedPrincipalIds: string[]) => {
    const idx = this.userOrganizations.findIndex((org) => org.organization.businessId === orgId)

    this.userOrganizations[idx].organizationUser.selectedPrincipalIds = selectedPrincipalIds
    this.setChangesMade(true)
  }

  postChangesToBackend = async (orgId: string, shouldRefreshToken: boolean = false) => {
    try {
      runInAction(() => {
        this.pendingRequest = 'loading'
      })

      const organisationToBeEdited = this.userOrganizations.find((org) => org.organization.businessId === orgId)

      const postObject = {
        roleValues: toJS(organisationToBeEdited.organizationUser.selectedRoles).filter((string) => string !== '*'),
        customerNumbers: handleNumbers(
          Boolean(this.customerNumbers),
          organisationToBeEdited.organizationUser.selectedCustomerNumbers
        ),
        logisticsContractNumbers: handleNumbers(
          Boolean(this.LSCNumbers),
          organisationToBeEdited.organizationUser.selectedLogisticsContractNumbers
        ),
        transportIds: handleNumbers(
          Boolean(this.transportIds),
          organisationToBeEdited.organizationUser.selectedTransportIds
        ),
        businessPartnerNumbers: handleNumbers(
          Boolean(this.businessPartnerNumbers),
          organisationToBeEdited.organizationUser.selectedBusinessPartnerNumbers
        ),
        principalIds: handleNumbers(
          Boolean(this.principalIds),
          organisationToBeEdited.organizationUser.selectedPrincipalIds
        ),
      }

      const response = await serviceAdapter.sendPostRequest(
        `/api/userdetails/${organisationToBeEdited.organizationUser.organizationUserId}`,
        postObject
      )

      if (response.status >= 400) {
        runInAction(() => {
          this.pendingRequest = 'error'
        })

        throw new Error('Bad response from server')
      }
      runInAction(() => {
        this.pendingRequest = 'success'

        if (shouldRefreshToken) {
          /**
           * Keep support for legacy feature loader
           */
          if (!(typeof authStore.refreshTokens === 'function')) {
            sendMessage({ type: OutboundMessageType.REFRESH, payload: null })
          } else {
            authStore.refreshTokens()
          }
        }
      })
    } catch (err) {
      console.error(err)
      runInAction(() => {
        this.pendingRequest = 'error'
      })
    }
  }

  setPendingRequest = (pendingRequest: string) => {
    this.pendingRequest = pendingRequest
  }

  removeOrganisation = async (businessId: string, accountId: string, email: string) => {
    try {
      runInAction(() => {
        this.pendingRequest = 'loading'
      })

      const response = await serviceAdapter.sendDeleteRequest(`/api/organization-users/${businessId}/${accountId}`)

      if (response.status >= 400) {
        runInAction(() => {
          this.pendingRequest = 'error'
          // Todo show error in userDetailsViews if error
        })

        throw new Error('Bad response from server')
      }

      runInAction(() => {
        this.pendingRequest = ''
        const filteredOrgs = toJS(this.userOrganizations).filter((org) => {
          return org.organization.businessId !== businessId
        })
        this.userOrganizations = filteredOrgs

        if (filteredOrgs.length === 0) {
          searchStore.removeDeletedUser(email)
        }
      })
    } catch (err) {
      console.error(err)
      runInAction(() => {
        this.pendingRequest = 'error'
      })
    }
  }
}

export const userDetailsStore = new UserDetailsStore()

const handleNumbers = (userNeedsNumbers: boolean, numbers: string[]): string[] => {
  if (!userNeedsNumbers) {
    return []
  }
  return toJS(numbers).filter((string) => string !== '*')
}
