import { useMemo } from 'react'

import { userStore } from '../store'
import { DropDownType } from '../store/dataModels/interfaces'
import { arrayIsEmpty } from './helpers'

export const useContractsValid = ({
  customerNumbers = [],
  businessPartnerNumbers = [],
  LSCNumbers = [],
  transportIds = [],
  principalIds = [],
  selectedCustomerNumbers = [],
  selectedBusinessPartnerNumbers = [],
  selectedLogisticsContractNumbers = [],
  selectedTransportIds = [],
  selectedPrincipalIds = [],
  selectedRoles = [],
  businessId,
}) =>
  useMemo(() => {
    // default to true if no contracts exist
    if (!customerNumbers && !businessPartnerNumbers && !LSCNumbers && !transportIds && !principalIds) {
      return true
    }

    const roles = userStore.getRolesThatRequireContracts(businessId, selectedRoles)

    const selectedTypes = []
    if (!arrayIsEmpty(selectedCustomerNumbers.slice())) {
      selectedTypes.push(DropDownType.CUSTOMER_NUMBER)
    }
    if (!arrayIsEmpty(selectedLogisticsContractNumbers.slice())) {
      selectedTypes.push(DropDownType.LOGISTICS_CONTRACT_NUMBER)
    }
    if (!arrayIsEmpty(selectedTransportIds.slice())) {
      selectedTypes.push(DropDownType.TRANSPORT_ID)
    }
    if (!arrayIsEmpty(selectedBusinessPartnerNumbers.slice())) {
      selectedTypes.push(DropDownType.BUSINESS_PARTNER_NUMBER)
    }
    if (!arrayIsEmpty(selectedPrincipalIds.slice())) {
      selectedTypes.push(DropDownType.PRINCIPAL_ID)
    }

    let requirementsOk = true
    roles.forEach((role) => {
      const requiredTypes = []
      if (role.customerNumberRequired) {
        requiredTypes.push(DropDownType.CUSTOMER_NUMBER)
      }
      if (role.logisticsContractNumberRequired) {
        requiredTypes.push(DropDownType.LOGISTICS_CONTRACT_NUMBER)
      }
      if (role.transportIdRequired) {
        requiredTypes.push(DropDownType.TRANSPORT_ID)
      }
      if (role.businessPartnerNumberRequired) {
        requiredTypes.push(DropDownType.BUSINESS_PARTNER_NUMBER)
      }
      if (role.principalIdRequired) {
        requiredTypes.push(DropDownType.PRINCIPAL_ID)
      }

      const intersection = requiredTypes.filter((item) => selectedTypes.indexOf(item) !== -1)
      if (intersection.length === 0) {
        requirementsOk = false
        return
      }
    })

    return requirementsOk
  }, [
    customerNumbers,
    businessPartnerNumbers,
    LSCNumbers,
    transportIds,
    principalIds,
    businessId,
    selectedRoles,
    selectedCustomerNumbers,
    selectedLogisticsContractNumbers,
    selectedTransportIds,
    selectedBusinessPartnerNumbers,
    selectedPrincipalIds,
  ])

export function useEditUserContractStates({
  customerNumbers = [],
  businessPartnerNumbers = [],
  LSCNumbers = [],
  transportIds = [],
  principalIds = [],
  selectedCustomerNumbers = [],
  selectedBusinessPartnerNumbers = [],
  selectedLogisticsContractNumbers = [],
  selectedTransportIds = [],
  selectedPrincipalIds = [],
}) {
  customerNumbers = customerNumbers || []
  businessPartnerNumbers = businessPartnerNumbers || []
  LSCNumbers = LSCNumbers || []
  transportIds = transportIds || []
  principalIds = principalIds || []
  selectedCustomerNumbers = selectedCustomerNumbers || []
  selectedBusinessPartnerNumbers = selectedBusinessPartnerNumbers || []
  selectedLogisticsContractNumbers = selectedLogisticsContractNumbers || []
  selectedTransportIds = selectedTransportIds || []
  selectedPrincipalIds = selectedPrincipalIds || []

  const hasSelectableCustomerNumbers = useMemo(
    () => customerNumbers.length > 0 || businessPartnerNumbers.length > 0,
    [customerNumbers, businessPartnerNumbers]
  )
  const hasSelectableLSCNumbers = useMemo(() => LSCNumbers?.length > 0, [LSCNumbers])
  const hasSelectableTransportIds = useMemo(() => transportIds?.length > 0, [transportIds])
  const hasSelectablePrincipalIds = useMemo(() => principalIds?.length > 0, [principalIds])
  const hasRemovableCustomerNumbers = useMemo(
    () =>
      !hasSelectableCustomerNumbers &&
      (selectedCustomerNumbers.length > 0 || selectedBusinessPartnerNumbers.length > 0),
    [hasSelectableCustomerNumbers, selectedCustomerNumbers, selectedBusinessPartnerNumbers]
  )
  const hasRemovableLSCNumbers = useMemo(
    () => !hasSelectableLSCNumbers && selectedLogisticsContractNumbers.length > 0,
    [hasSelectableLSCNumbers, selectedLogisticsContractNumbers]
  )
  const hasRemovableTransportIds = useMemo(
    () => !hasSelectableTransportIds && selectedTransportIds.length > 0,
    [hasSelectableTransportIds, selectedTransportIds]
  )
  const hasRemovablePrincipalIds = useMemo(
    () => !hasSelectablePrincipalIds && selectedPrincipalIds.length > 0,
    [hasSelectablePrincipalIds, selectedPrincipalIds]
  )
  const hasRemovableContracts = useMemo(
    () => hasRemovableCustomerNumbers || hasRemovableLSCNumbers || hasRemovableTransportIds || hasRemovablePrincipalIds,
    [hasRemovableCustomerNumbers, hasRemovableLSCNumbers, hasRemovableTransportIds, hasRemovablePrincipalIds]
  )
  const hasContractSelections = useMemo(
    () =>
      hasSelectableCustomerNumbers || hasSelectableLSCNumbers || hasSelectableTransportIds || hasSelectablePrincipalIds,
    [hasSelectableCustomerNumbers, hasSelectableLSCNumbers, hasSelectableTransportIds, hasSelectablePrincipalIds]
  )

  return {
    hasSelectableCustomerNumbers,
    hasSelectableLSCNumbers,
    hasSelectableTransportIds,
    hasSelectablePrincipalIds,
    hasRemovableCustomerNumbers,
    hasRemovableLSCNumbers,
    hasRemovableTransportIds,
    hasRemovablePrincipalIds,
    hasRemovableContracts,
    hasContractSelections,
  }
}
