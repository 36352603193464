export default {
  general: {
    mainTitle: 'All set!',
    next: 'Next',
    back: 'Back',
    done: 'Done',
    name: 'Name',
    active: 'Active',
    delete: 'Delete',
    expired: 'Expired',
    users: 'Users',
    showMore: 'Show more',
    showLess: 'Show less',
    ready: 'Ready',
    resend: 'Resend',
    cancel: 'Cancel',
    fetching: 'Fetching',
    sent: 'Sent',
    created: 'Created',
    valid: 'Valid',
    contractNumbers: 'Contracts:',
    customerNumber: 'Customer number',
    customerNumbers: 'Customer numbers',
    LCSNumber: 'Logistics contract number',
    LCSNumbers: 'Logistics contract numbers',
    transportId: 'Transport ID',
    transportIds: 'Transport IDs',
    principalId: 'Principal ID',
    principalIds: 'Principal IDs',
    missingContractNumber: 'The selected access rights require you to select contract numbers',
    contractNumbersUnselected: 'The selected access rights do not require the following contract numbers',
    contractNumbersNotNeeded: 'The access rights for the user do not require contract numbers.',
    contractNumbersWillBeRemoved: 'The unnecessary contract numbers will be removed from the user.',
    or: 'or',
    requires: 'requires at least one',
    requiresCustomerNumbers: 'customer number',
    requiresLogisticsContractNumbers: 'logistics contract number',
    requiresTransportIds: 'transport ID',
    requiresPrincipalIds: 'principal ID',
    selectCustomerNumbers: 'Select customer number(s):',
    selectLogisticsContractNumbers: 'Select logistics contract number(s) - Enables Posti\'s parcel and letter services:',
    selectTransportIds: 'Select transport ID(s) - Enables OmaPosti Pro freight services:',
    selectPrincipalIds: 'Select principal ID(s) - Enables Warehouse services:',
    contractInfo:
      'You can also select multiple kinds of contract numbers.',
    selectAll: 'Select all',
    select: 'Select:',
    edit: 'Edit',
    close: 'Close',
    makeChanges: 'Save',
    firstName: 'First name',
    lastName: 'Last name',
    phone: 'Phone',
    organisations: 'Organizations',
    mainUsers: 'Main users',
    invites: 'Invitations',
    orgDetails: 'Organization details',
    email: 'Email',
    add: 'Add',
    cancelled: 'Cancelled',
    accepted: 'Accepted',
    businessId: 'Business ID',
    loading: 'Loading...',
    roleRequirementInfo: 'These access rights require contract numbers (see next page).',
    roleRequirementInfo2: 'These access rights require contract numbers (see end of the page).',
    new: 'New',
  },
  errors: {
    general: {
      title: 'Something went wrong',
    },
    badUrl: {
      title: 'This link is no longer valid',
      body: 'Contact the main user in your organization.',
    },
    invite_general: {
      title: 'Something went wrong',
    },
    invite_email_conflict: {
      title: 'Invitation could not be sent',
      body:
        'Unfortunately the invitation can not be sent to the email address you chose. Please contact Posti\'s customer services to fix this issue or send the invitation to some other emaill address!',
    },
    invite_email_validation_conflict: {
      title: 'Invite can\'t be sent to this email',
    },
    refresh: 'Back to Start',
    reload: 'Refresh Application',
  },
  invite: {
    send: 'Send invitation',
    selectOne: 'Select at least one',
    sending: 'Sending invitation...',
    success: 'Your invitation was sent',
    error: 'A problem occurred while sending the invitation',
    sendAnotherInvite: 'Send another invitation',
    inviteDetailsTitle: 'Invitation details',
    inviteUser: 'Invite User',
    userUsername: 'Email (Username)',
    userType: 'User type',
    accessRights: 'Access rights',
    customerNumbersContracts: 'Contracts',
    orgAndUsers: 'Organisation & Users',
    createUser: 'Create New User',
    selectOrg: '1. Select an Organization',
    addEmail: '2. Add emails for users who should get the same user rights',
    invPeriod: 'Invitation Period',
    invInfo:
      'The user will be requested to create an account via email. The link in the email will remain valid for 7 days. The user will get the access rights defined by you.',
    removeEmailButtonLabel: 'Remove email {{email}}',
    rolesRequired: 'The user has to get rights to at least one organisation',
  },
  landing: {
    dayleft: 'days left',
    userManagement: 'Manage Users',
    invitations: 'Invitations',
    inviteUser: 'Invite User',
    search: 'Search users or invitations',
    download: 'Download CSV',
  },
  editUser: {
    title: 'User Details',
    sending: 'We\'re saving your changes, please wait...',
    success: 'Your changes have been made',
    error: 'A problem occurred while making changes',
    makeChanges: 'You can change the user details here.',
    complete: 'Select Next to make changes.',
    pressButtonToMakeChange: 'Select Save to make changes.',
  },
  cnToolTipContent: {
    firstPart: 'The following services require this:',
    title: 'Postal Services',
    list1: 'OmaPosti reports',
    list2: 'Electronic mailing list, Kontakti',
    list3: 'Mailing Desktop (PTP)',
    list4: 'Envelope shop',
    list5: 'Franking machine',
    list6: 'Invoice report',
    list7: 'Press summary',
    list8: 'Service summary',
    list9: 'Advice of Delivery',
  },
  LSCNToolTipContent: {
    title: 'Logistic services with a contract number',
    list1: 'International returns',
    list2: 'Advice of Delivery',
  },
  removeModal: {
    title: 'Removing user rights',
    content: 'Do you want to remove user\'s access to this organization?',
    removeButton: 'Remove',
  },
  filter: {
    filter: 'Filter',
    orgInfo: 'Organizational information',
    clearAll: 'Clear all filters',
    byService: 'Filter by service',
    done: 'Done',
  },
  csat: {
    title: 'How was this experience?',
    thanks: {
      title: 'Thank you for your feedback!',
      text: 'Your input helps us improve our service.',
    },
    terrible: {
      label: 'Terrible',
      aria: 'Terrible experience',
    },
    bad: {
      label: 'Bad',
      aria: 'Bad experience',
    },
    ok: {
      label: 'Ok',
      aria: 'Ok experience',
    },
    good: {
      label: 'Good',
      aria: 'Good experience',
    },
    great: {
      label: 'Great',
      aria: 'Great experience',
    },
  },
}
