import { Modal, ModalStateWrapper } from '@postidigital/posti-components'
import { Button, ChevronUpIcon, CloseIcon, PlusIcon, Search } from '@postidigital/posti-components/build/brand'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { ChangeEventHandler, useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { FilterDropdown, FilterServicesList, MainUserRadioGroup } from '../../components'
import { StoreContext } from '../../store'
import { DropDownType } from '../../store/dataModels/interfaces'
import { ContentSection } from '../../style/layout'
import { breakpoint } from '../../utils/breakpoint'
import { arrayIsEmpty } from '../../utils/helpers'
import { FilterButton } from '../buttons/buttons'

const SearchAndFilterComponent: React.FC = () => {
  const { t } = useTranslation()
  const [filterOpen, setFilterOpen] = useState<boolean>(false)
  const { layoutStore, searchStore } = useContext(StoreContext)
  const navigate = useNavigate()
  const { isMobile } = layoutStore

  const {
    searchTerm,
    searchMainUsers,
    organisationsOptions,
    selectedOrganisations,
    customerNumbers,
    selectedCustomerNumbers,
    LSCN,
    selectedLSCN,
    transportIds,
    selectedTransportIds,
    principalIds,
    selectedPrincipalIds,
    toggleSearchMainUsers,
    setSelectedOrganisations,
    setSelectedCustomerNumbers,
    setSelectedLSCN,
    setSelectedTransportIds,
    setSelectedPrincipalIds,
    clearFilters,
    setSearchTerm,
  } = searchStore

  useEffect(() => {
    if (!isMobile) {
      setFilterOpen(false)
    }
  }, [isMobile])

  const onButtonClick = useCallback(() => {
    navigate('/invite/1')
  }, [navigate])

  const handleToggleFilters = useCallback(() => {
    setFilterOpen((prevState) => !prevState)
  }, [])

  const handleSearch: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      e.preventDefault()

      if (!e?.target?.value) {
        return setSearchTerm('')
      }

      setSearchTerm(e.target.value)

      return searchStore.debouncedFetchResults()
    },
    [searchStore, setSearchTerm]
  )

  const allOption = {
    label: t(`general.selectAll`),
    value: '*',
    type: DropDownType.SELECT_ALL,
  }

  const orgsToJS = toJS(organisationsOptions)
  const selectedOrganisationsToJS = toJS(selectedOrganisations)
  const orgsSelected = !arrayIsEmpty(selectedOrganisationsToJS)
  const filtersSelected = Boolean(orgsSelected || searchMainUsers)

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', flexWrap: 'wrap' }}>
      {isMobile && (
        <>
          <FilterButton selected={filtersSelected} onClick={handleToggleFilters} />
          <ModalStateWrapper open={filterOpen}>
            <Modal mobileMode={'fullscreen'}>
              <Modal.CloseButton type="button" aria-label="Close" onClick={handleToggleFilters} icon={CloseIcon} />
              <Modal.Content>
                <Modal.MainContent>
                  <ContentSection>
                    <Button
                      style={{ width: 'fit-content' }}
                      hasBackground={false}
                      rightIcon={CloseIcon}
                      size="sm"
                      contentMode="secondary"
                      onClick={() => clearFilters()}
                    >
                      {t('filter.clearAll')}
                    </Button>
                    <MainUserRadioGroup
                      small
                      plurarTranslations
                      isMainUser={searchMainUsers}
                      toggleUserRole={toggleSearchMainUsers}
                    />

                    <FilterDropdown
                      id="organization_selector"
                      key="organization_selector"
                      options={orgsToJS}
                      selectedOptions={selectedOrganisationsToJS}
                      allowSelectAll
                      isMulti
                      allOption={allOption}
                      placeHolderText={t('general.organisations')}
                      onChange={setSelectedOrganisations}
                    />

                    {customerNumbers?.length > 0 && (
                      <FilterDropdown
                        id="customer_number_selector"
                        key="customer_number_selector"
                        disabled={!orgsSelected}
                        options={toJS(customerNumbers)}
                        selectedOptions={toJS(selectedCustomerNumbers)}
                        allowSelectAll
                        isMulti
                        grouped
                        allOption={allOption}
                        placeHolderText={t('general.customerNumbers')}
                        onChange={setSelectedCustomerNumbers}
                      />
                    )}

                    {LSCN?.length > 0 && (
                      <FilterDropdown
                        id="lcsnumbers_selector"
                        key="lcsnumbers_selector"
                        disabled={!orgsSelected}
                        options={toJS(LSCN)}
                        selectedOptions={toJS(selectedLSCN)}
                        allowSelectAll
                        isMulti
                        grouped
                        allOption={allOption}
                        placeHolderText={t('general.LCSNumbers')}
                        onChange={setSelectedLSCN}
                      />
                    )}

                    {transportIds?.length > 0 && (
                      <FilterDropdown
                        id="transportid_selector"
                        key="transportid_selector"
                        disabled={!orgsSelected}
                        grouped
                        options={transportIds}
                        selectedOptions={toJS(selectedTransportIds)}
                        allowSelectAll
                        isMulti
                        allOption={allOption}
                        placeHolderText={t('general.transportIds')}
                        onChange={setSelectedTransportIds}
                      />
                    )}

                    {principalIds?.length > 0 && (
                      <FilterDropdown
                        id="principalid_selector"
                        key="principalid_selector"
                        disabled={!orgsSelected}
                        grouped
                        options={principalIds}
                        selectedOptions={toJS(selectedPrincipalIds)}
                        allowSelectAll
                        isMulti
                        allOption={allOption}
                        placeHolderText={t('general.principalIds')}
                        onChange={setSelectedPrincipalIds}
                      />
                    )}

                    {orgsSelected && <FilterServicesList />}
                  </ContentSection>
                  <ContentSection top="lg">
                    <StyledButton2 id="btn_close_filter" onClick={handleToggleFilters} rightIcon={ChevronUpIcon}>
                      {t('filter.done')}
                    </StyledButton2>
                  </ContentSection>
                </Modal.MainContent>
              </Modal.Content>
            </Modal>
          </ModalStateWrapper>
        </>
      )}
      <StyledSearch>
        <Search
          onChange={handleSearch}
          debounceTime={1000}
          value={searchTerm}
          label={t(`landing.search`)}
          id="search-1"
          clearButtonProps={{
            id: 'search_1_clear_button',
            onClick: () => setSearchTerm(''),
          }}
        />
      </StyledSearch>

      <Button
        css
        rightIcon={PlusIcon}
        id="invite-user-button"
        onClick={onButtonClick}
        hasBackground={!isMobile}
        size={isMobile ? 'sm' : 'md'}
        contentMode={isMobile ? 'tertiary' : 'primary'}
      >
        {t(`landing.inviteUser`)}
      </Button>
    </div>
  )
}

export const SearchAndFilter = observer(SearchAndFilterComponent)

const StyledSearch = styled('div')`
  min-width: 200px;

  &,
  & > div,
  & > div > div {
    display: flex;
    flex: 1;
  }

  @media ${breakpoint.mobile} {
    min-width: 100%;
    order: 3;
  }
`

const StyledButton2 = styled(Button)`
  width: 100%;
`
