export default {
  general: {
    mainTitle: 'Färdig!',
    next: 'Nästa',
    back: 'Tillbaka',
    done: 'Färdig',
    name: 'Namn',
    active: 'Aktiv',
    delete: 'Ta bort',
    expired: 'Utgånget',
    users: 'Användare',
    showMore: 'Visa mera',
    showLess: 'Visa mindre',
    ready: 'Färdig',
    resend: 'Sicka på nytt',
    cancel: 'Annullera',
    fetching: 'Hämtar',
    sent: 'Skickad',
    created: 'Skapad',
    valid: 'Giltighetstid',
    contractNumbers: 'Avtals:',
    customerNumber: 'Kundnummer',
    customerNumbers: 'Kundnummer',
    LCSNumber: 'Logistikavtalsnummer',
    LCSNumbers: 'Logistikavtalsnummer',
    transportId: 'Frakt avtalsnummer',
    transportIds: 'Avtalsnummer för frakten',
    principalId: 'Uppdragsgivare-ID',
    principalIds: 'Uppdragsgivare-ID:n',
    missingContractNumber: 'För de valda användningsbehörigheterna måste du välja avtalsnummer',
    contractNumbersNotNeeded: 'De valda användningsbehörigheterna kräver inte avtalsnummer.',
    or: 'eller',
    requires: 'kräver minst',
    requiresCustomerNumbers: 'ett kundnummer',
    requiresLogisticsContractNumbers: 'ett avtalsnummer för logistiken',
    requiresTransportIds: 'ett avtalsnummer för frakten',
    requiresPrincipalIds: 'ett uppdragsgivare-ID',
    selectCustomerNumbers: 'Välj kundnummer:',
    selectLogisticsContractNumbers: 'Välj logistikavtalsnummer – Aktiverar Postis paket- och brevtjänster:',
    selectTransportIds: 'Välj frakt avtalsnumer – Aktiverar OmaPosti Pro-frakttjänster:',
    selectPrincipalIds: 'Välj uppdragsgivare-ID:n - Aktiverar Posti :',
    contractInfo: 'Du kan också välja flera olika typer av avtalsnummer.',
    selectAll: 'Välj alla',
    edit: 'Ändra',
    close: 'Stäng',
    makeChanges: 'Spara',
    firstName: 'Förnamn',
    lastName: 'Efternamn',
    phone: 'Telefon',
    organisations: 'Organisationer',
    mainUsers: 'Huvudanvändare',
    invites: 'Inbjudan',
    orgDetails: 'Organization details',
    email: 'E-post',
    add: 'Lägg till',
    cancelled: 'Inställt',
    accepted: 'Accepterad',
    businessId: 'Affärs-ID',
    loading: 'Laddas...',
    roleRequirementInfo: 'Dessa användningsbehörigheter kräver avtalsnummer (se nästa sida).',
    roleRequirementInfo2: 'Dessa användningsbehörigheter kräver avtalsnummer (se sidans slut).',
    new: 'Ny',
  },
  errors: {
    general: {
      title: 'Någonting gick fel',
    },
    badUrl: {
      title: 'Denhär länken är inte mera valid',
      body: 'Var snäll och kontakta din organsations huvudanvändare',
    },
    invite_general : {
      title: 'Någonting gick fel',
    },
    invite_email_conflict: {
      title: 'Inbjudan kunde inte skickas',
      body: 'Tyvärr kan inbjudan inte skickas till den e-postadress du har valt. Vänligen kontakta Postis kundtjänst eller skicka inbjudan till någon annan adress.',
    },
    invite_email_validation_conflict: {
      title: 'Inbjudan kan inte sändas till denna e-post',
    },
    refresh: 'Tillbaka till början',
    reload: 'Uppdatera applikationen',
  },
  invite: {
    send: 'Skicka inbjudan',
    selectOne: 'Välj minst en',
    sending: 'Skickar inbjudningar...',
    success: 'Dina inbjudningar har skickas',
    error: 'Det gick inte att skicka dina inbjudningar',
    sendAnotherInvite: 'Sicka en till inbjudan',
    inviteDetailsTitle: 'Injudans detaljer',
    inviteUser: 'Bjud in användare',
    userUsername: 'E-posten (Användarnamn)',
    userType: 'Användar typ',
    accessRights: 'Behörigheter',
    customerNumbersContracts: 'Avtal',
    orgAndUsers: 'Organisation & användare',
    createUser: 'Skapa ny användare',
    selectOrg: '1. Välj en organisation',
    addEmail: '2. Lägg till e-posten som skall få användarbehörigheter',
    invPeriod: 'Giltighetsperiod för inbjudan',
    invInfo:
      'Användaren kommer att få en e-postlänk som gäller i 7 dagar. Användaren får de behörigheter som du har definierat',
    removeEmailButtonLabel: 'Ta bort e-posten {{email}}',
    rolesRequired: 'Användaren måste ges behörighet till minst en organisation',
  },
  landing: {
    dayleft: 'dag(ar) kvar',
    userManagement: 'Användarhantering',
    invitations: 'Inbjudningar',
    inviteUser: 'Bjud in användare',
    search: 'Sök användare eller inbjudan',
    download: 'Ladda ner CSV',
  },
  editUser: {
    title: 'Användarinformation',
    sending: 'Vi sparar dina ändringar, var god och vänta...',
    success: 'Ändringar sparade',
    error: 'Det gick inte att spara dina ändringar',
    makeChanges: 'Här kan du ändra användarens behörigheter.',
    complete: 'Tryck Nästa för att göra ändringar.',
    pressButtonToMakeChange: 'Tryck Spara för att göra ändringar.',
  },
  cnToolTipContent: {
    firstPart: 'Följande servisar behöver det här',
    title: 'Postal services',
    list1: 'OmaPosti raporter',
    list2: 'Kontakti',
    list3: 'PTP (Planeringsapplication för postining)',
    list4: 'Postkuvertbutik',
    list5: 'Frankeringsmaskin',
    list6: 'Invoice report',
    list7: 'Press summary',
    list8: 'Service summary',
    list9: 'Advice of delivery',
  },
  LSCNToolTipContent: {
    title: 'Logistiktjänst med avtalsnummer',
    list1: 'International return',
    list2: 'Advice of delivery',
  },
  removeModal: {
    title: 'Ta bort behörigheter',
    content: 'Vill du ta bort behörigheter till den här organisationen?',
    removeButton: 'Ta bort',
  },
  filter: {
    filter: 'Filtrera',
    orgInfo: 'Organisation information',
    clearAll: 'Töm filter',
    byService: 'Filtrera efter service',
    done: 'Färdig',
  },
  csat: {
    title: 'Hur skulle du beskriva din upplevelse?',
    thanks: {
      title: 'Tack för din respons!',
      text: 'Din respons hjälper oss att förbättra tjänsten.',
    },
    terrible: {
      label: 'Förfärlig',
      aria: 'En förfärlig upplevelse',
    },
    bad: {
      label: 'Dålig',
      aria: 'En dålig upplevelse',
    },
    ok: {
      label: 'Okej',
      aria: 'En okej upplevelse',
    },
    good: {
      label: 'Bra',
      aria: 'En bra upplevelse',
    },
    great: {
      label: 'Utmärkt',
      aria: 'En utmärkt upplevelse',
    },
  },
}
