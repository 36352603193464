import { Body, Notification } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { CloseButton } from '../../components'
import { StoreContext } from '../../store'
import { breakpoint } from '../../utils/breakpoint'
import { EditInfoHeader } from './editInfoHeader'

const EditTopSectionComponent: React.FC = () => {
  const [t] = useTranslation()
  const { userDetailsStore } = useContext(StoreContext)
  const navigate = useNavigate()

  const { changesMade, step, showChangesMadeNotification } = userDetailsStore

  const renderNoti = useMemo(() => {
    if (step === 3) {
      return
    }

    const getNotiText = (): string => {
      if (step === 2) {
        return !changesMade ? t(`editUser.makeChanges`) : t(`editUser.pressButtonToMakeChange`)
      }
      return !changesMade ? t(`editUser.makeChanges`) : t(`editUser.complete`)
    }

    return (
      <StyledNotiWrapper>
        <Notification contentType={!changesMade ? 'success' : 'warning'} dismissible={false}>
          <StyledBody as="span" size="Four">
            {getNotiText()}
          </StyledBody>
        </Notification>
      </StyledNotiWrapper>
    )
  }, [changesMade, step, t])

  const goBack = useCallback(() => navigate(-1), [navigate])

  return (
    <>
      <StyledButtonWrapper>
        <CloseButton onClick={goBack} />
      </StyledButtonWrapper>
      <EditInfoHeader />
      {showChangesMadeNotification && renderNoti}
    </>
  )
}

export const EditTopSection = observer(EditTopSectionComponent)

const StyledButtonWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 16px 0;
  @media ${breakpoint.mobile} {
    padding-top: 0;
  }
`

const StyledNotiWrapper = styled('div')`
  margin-top: 8px;
  margin-bottom: 40px;
`

const StyledBody = styled(Body)`
  font-weight: 600;
  margin: 0;
`
